<template>
    <el-main>
        <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first"></el-tab-pane>
            <el-tab-pane label="商品规格" name="second"></el-tab-pane>
        </el-tabs>
        <el-form :model="goodsForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <div v-if="activeName == 'first'">
                <el-form-item label="菜品分类：" class="item goods-type" prop="classify_id">
                    <el-select v-model="goodsForm.classify_id" filterable>
                        <el-option v-for="item in goodsClass" :key="item.id" :label="item.classify_name"
                            :value="item.id"></el-option>
                    </el-select>
                    <router-link to="/catering/goodsType" style="margin-left: 15px">
                        <el-button type="text">没有分类，去添加分类</el-button>
                    </router-link>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model="goodsForm.sort" type="number"></el-input>
                </el-form-item>
                <el-form-item label="菜品名称：" prop="cuisine_name">
                    <el-input v-model="goodsForm.cuisine_name"></el-input>
                </el-form-item>
                <el-form-item label="菜品图片：" prop="cuisine_picture">
                    <ReadyUploadSource type="image" :path="goodsForm.cuisine_picture"
                        @removeThis="() => (goodsForm.cuisine_picture = '')"
                        @getSource="(val) => (goodsForm.cuisine_picture = val.path)"></ReadyUploadSource>
                    <p class="tips">
                        建议图片尺寸750px*750px，大小不超过1M
                    </p>
                </el-form-item>
                <el-form-item label="价格（元）" prop="price">
                    <el-input v-model="goodsForm.price"></el-input>
                </el-form-item>
                <el-form-item label="菜品状态：" required>
                    <el-radio v-model="goodsForm.status" :label="1">上架</el-radio>
                    <el-radio v-model="goodsForm.status" :label="0">下架</el-radio>
                </el-form-item>
            </div>
            <div v-if="activeName == 'second'">
                <el-form-item label="是否使用规格：" class="enable-spec">
                    <el-radio v-model="goodsForm.is_spec" :label="1">是</el-radio>
                    <el-radio v-model="goodsForm.is_spec" :label="0">否</el-radio>
                </el-form-item>
                <template v-if="goodsForm.is_spec == 1">
                    <el-form-item label="规格名称：" class="enable-spec" prop="sku_title">
                        <el-input v-model="goodsForm.sku_title"></el-input>
                    </el-form-item>
                    <el-form-item label=" " prop="goods_sku">
                        <el-table :data="goodsForm.goods_sku" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                            row-key="id">
                            <el-table-column align="center">
                                <template #header>
                                    <span style="color: red">*</span>
                                    <span>规格</span>
                                </template>
                                <template v-slot="{ row }">
                                    <el-input size="small" v-model="row.sku_name"
                                        :disabled="row.id && !row.is_edit"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template #header>
                                    <span style="color: red">*</span>
                                    <span>单价</span>
                                </template>
                                <template v-slot="{ row }">
                                    <el-input size="small" v-model="row.sku_price"
                                        :disabled="row.id && !row.is_edit"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column align="center">
                                <template #header>
                                    <span style="color: red">*</span>
                                    <span>图片</span>
                                </template>
                                <template v-slot="{ row }">
                                    <div class="spec_img">
                                        <ReadyUploadSource :showStyle="{
                                            width: '70px',
                                            height: '70px',
                                        }" type="image" :path="row.sku_picture"
                                            @removeThis="() => (row.sku_picture = '')"
                                            @getSource="(val) => (row.sku_picture = val.path)"
                                            v-if="row.is_edit || !row.id"></ReadyUploadSource>
                                        <img :src="row.sku_picture" alt="" style="width:70px;height: 70px;" v-else>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" v-if="goodsForm.goods_sku.length > 1">
                                <template v-slot="{ row, $index }">
                                    <el-button type="text" @click="handleDeleteSku(row, $index)">删除</el-button>
                                    <el-button type="text" v-if="row.id && !row.is_edit"
                                        @click="row.is_edit = true">编辑</el-button>
                                    <el-button type="text" v-if="row.id && row.is_edit" @click="saveSku(row)">保存</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="text" @click="addSpecFn">+ 添加规格</el-button>
                    </el-form-item>
                    <el-form-item label="做法规格：" prop="deliver_method">
                        <div style="display: flex;">
                            <div class="like_input">
                                <el-tag closable size="medium" v-for="item in goodsForm.practice_ids" :key="item"
                                    :disable-transitions="false" @close="handleClose(item)">{{
                                        practiceLists.find(v => v.id == item) ? practiceLists.find(v => v.id ==
                                            item).practice_name : '--' }}</el-tag>
                            </div>
                            <el-button type="primary" @click="dialogVisible = true">选择</el-button>
                        </div>
                        <div class="tips">做法分类适用于奶茶甜品店铺，其他餐饮如果没有加价，可不添加</div>
                    </el-form-item>
                </template>
            </div>
        </el-form>
        <el-dialog title="做法规格" :visible.sync="dialogVisible" width="40%">
            <el-table :data="practiceList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                <el-table-column prop="practice_name" label="做法名称" align="center"></el-table-column>
                <el-table-column prop="practice_classify_name" label="做法分类" align="center"></el-table-column>
                <el-table-column prop="price" label="加价价格" align="center">
                    <template v-slot="{ row }">￥{{ row.price }}</template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot="{ row }">
                        <el-button type="text" v-if="goodsForm.practice_ids.some(v => v == row.id)"
                            @click="handleClose(row.id)">取消选择</el-button>
                        <el-button type="text" v-else @click="goodsForm.practice_ids.push(row.id)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <Paging :total="total_number" :page="practiceForm.page" :pageNum="practiceForm.rows"
                @updatePageNum="updateData">
            </Paging>
        </el-dialog>
        <Preservation @preservation="goodsSubmit"></Preservation>
    </el-main>
</template>

<script>
import ReadyUploadSource from "@/components/readyUploadSource";
import Preservation from "@/components/preservation";
import Paging from '@/components/paging';
import { inspectPrice } from '@/util/verification.js';
import _ from "lodash";
export default {
    components: {
        ReadyUploadSource,
        Preservation,
        Paging
    },
    data () {
        let that = this;
        function checkAttrs (rule, value, callback) {
            let flag = that.goodsForm.goods_sku.every(v => v.sku_name && v.sku_price && v.sku_picture);
            if (!flag) {
                callback(new Error("请将数据填写完整"));
            } else {
                callback();
            }
        }
        return {
            g_id: null,
            activeName: "first",

            // 添加的商品数据
            goodsForm: {
                classify_id: '',
                sort: 0,
                cuisine_name: '',
                cuisine_picture: '',
                price: '',
                status: 1,
                is_spec: 0,
                sku_title: '',
                goods_sku: [
                    {
                        sku_name: '',
                        sku_price: '',
                        sku_picture: ''
                    }
                ],
                practice_ids: []
            },
            rules: {
                classify_id: [{ required: true, message: "请选择菜品分类", trigger: "change" }],
                cuisine_name: [{ required: true, message: "请填写菜品名称", trigger: "blur" }],
                sort: [{ required: true, message: "请填写排序", trigger: "blur" }],
                cuisine_picture: [{ required: true, message: "请上传菜品图片", trigger: "change" }],
                price: [{ required: true, message: "请填写价格", trigger: "blur" }, {
                    validator: (rule, value, callback) => {
                        if (!inspectPrice(value)) {
                            callback(new Error("请填写正确价格格式"));
                        } else {
                            callback();
                        }
                    }, trigger: "blur"
                }],
                sku_title: [{ required: true, message: "请填写规格名称", trigger: "blur" }],
                goods_sku: [{ validator: checkAttrs, trigger: "blur" }]
            },
            dialogVisible: false,
            total_number: 0,
            goodsClass: [],
            practiceForm: {
                page: 1,
                rows: 10
            },
            practiceList: [],
            practiceLists: []
        };
    },
    created () {
        this.getGoodsClassifyLists()
        this.getGoodsPracticeList()
        this.getGoodsPracticeLists()
    },
    methods: {
        getGoodsClassifyLists () {
            this.$axios.post(this.$api.catering.GoodsClassifyLists).then(res => {
                if (res.code == 0) {
                    this.goodsClass = res.result
                    if (this.$route.query.g_id) {
                        this.g_id = this.$route.query.g_id;
                        this.getGoodsInfo();
                        this.getGoodsSkuList()
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getGoodsPracticeList () {
            this.$axios.post(this.$api.catering.GoodsPracticeList, this.practiceForm).then(res => {
                if (res.code == 0) {
                    this.practiceList = res.result.list
                    this.total_number = res.result.total_number
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        getGoodsPracticeLists () {
            this.$axios.post(this.$api.catering.GoodsPracticeList, {
                page: 1,
                rows: 1000
            }).then(res => {
                if (res.code == 0) {
                    this.practiceLists = res.result.list
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        updateData (val, status) {
            if (status == 0) {
                this.practiceForm.rows = val;
            } else {
                this.practiceForm.page = val;
            }
            this.getGoodsPracticeList();
        },
        handleClose (tag) {
            this.goodsForm.practice_ids.splice(this.goodsForm.practice_ids.findIndex(v => v == tag), 1);
        },
        // 点击添加新的规格
        addSpecFn () {
            this.goodsForm.goods_sku.push({
                sku_name: '',
                sku_price: '',
                sku_picture: ''
            })
        },
        // 点击添加商品
        goodsSubmit () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let obj = _.cloneDeep(this.goodsForm);

                    let path = this.g_id ? this.$api.catering.GoodsEdit : this.$api.catering.GoodsAdd
                    if (this.g_id) {
                        if (obj.is_spec) {
                            if (obj.goods_sku.some(v => v.is_edit)) {
                                return this.$message.warning('还有没保存的规格')
                            }
                            let addSku = obj.goods_sku.filter(v => !v.id)
                            if (addSku.length) {
                                let msg = ''
                                addSku.forEach(async (item) => {
                                    let res = await this.$axios.post(this.$api.catering.GoodsSkuAdd, {
                                        goods_id: this.g_id,
                                        sku_name: item.sku_name,
                                        sku_price: item.sku_price,
                                        sku_picture: item.sku_picture,
                                    })
                                    if (res.code != 0) {
                                        msg = res.msg
                                    }
                                })
                                if (msg) return this.$message.error(msg);
                            }
                        }
                        obj.id = this.g_id
                        delete obj.goods_sku
                    }

                    this.$axios.post(path, obj).then((res) => {
                        if (res.code == 0) {
                            let str = this.g_id ? '编辑菜品成功' : "添加菜品成功"
                            this.$message.success(str);
                            this.$router.go(-1)
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        // 查看商品详情
        getGoodsInfo () {
            this.$axios
                .post(this.$api.catering.GoodsInfo, {
                    id: this.g_id,
                })
                .then((res) => {
                    if (res.code === 0) {
                        let info = res.result
                        this.goodsForm.classify_id = info.classify_id
                        this.goodsForm.sort = info.sort
                        this.goodsForm.cuisine_name = info.cuisine_name
                        this.goodsForm.cuisine_picture = info.cuisine_picture
                        this.goodsForm.price = info.price
                        this.goodsForm.status = info.status
                        this.goodsForm.is_spec = info.is_spec
                        this.goodsForm.sku_title = info.sku_title
                        this.goodsForm.practice_ids = info.practice_ids || []
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getGoodsSkuList () {
            this.$axios.post(this.$api.catering.GoodsSkuList, {
                goods_id: this.g_id
            }).then(res => {
                if (res.code == 0) {
                    let goods_sku = res.result
                    goods_sku.forEach(item => {
                        item.is_edit = false
                    })
                    this.goodsForm.goods_sku = goods_sku
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleDeleteSku (row, $index) {
            if (row.id) {
                this.$confirm('此操作将删除该规格, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.post(this.$api.catering.GoodsSkuDel, {
                        id: row.id
                    }).then(res => {
                        if (res.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.goodsForm.goods_sku.splice($index, 1)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }).catch(() => { });
            } else {
                this.goodsForm.goods_sku.splice($index, 1)
            }
        },
        saveSku (row) {
            this.$axios.post(this.$api.catering.GoodsSkuEdit, {
                id: row.id,
                sku_name: row.sku_name,
                sku_price: row.sku_price,
                sku_picture: row.sku_picture,
            }).then(res => {
                if (res.code == 0) {
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    row.is_edit = false
                } else {
                    this.$message.error(res.msg)
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;
    padding-bottom: 100px;

    .tips {
        color: #999;
    }

    .el-input {
        width: 400px;
    }

    .attrsBox {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-top: 10px;
        }

        .el-input {
            &:not(:last-child) {
                margin-right: 20px;
            }

            width: 190px;
        }

        .deleteAttrs {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .el-table {
        .el-input {
            max-width: 150px;
        }

        .spec_img {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 80px;
        }
    }
}

.like_input {
    width: 400px;
    padding: 6px 15px 0;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px 0 0 4px;
    flex-wrap: wrap;
    min-height: 40px;

    .el-tag {
        margin-right: 10px;
        margin-bottom: 6px;
    }
}
</style>
